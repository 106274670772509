@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 20 14.3% 4.1%;
    --inverted: 240 10% 3.9%;
    --inverted-foreground: 0 0% 98%;
    --card: 0 0% 100%;
    --card-foreground: 20 14.3% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 20 14.3% 4.1%;
    --primary: 24.6 95% 53.1%;
    --primary-foreground: 60 9.1% 97.8%;
    --secondary: 0 0% 100%;
    --secondary-foreground: 20 14.3% 4.1%;
    --muted: 60 4.8% 95.9%;
    --muted-foreground: 25 5.3% 44.7%;
    --accent: 0 0% 89%;
    --accent-foreground: 24 9.8% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 20 5.9% 90%;
    --input: 20 5.9% 90%;
    --ring: 24.6 95% 53.1%;
    --radius: 0.75rem;
  }

  .dark {
    --background: 20 14.3% 4.1%;
    --foreground: 60 9.1% 97.8%;
    --inverted: 0 0% 98%;
    --inverted-foreground: 240 10% 3.9%;
    --card: 24 9.8% 10%;
    --card-foreground: 0 0% 95%;
    --popover: 0 0% 9%;
    --popover-foreground: 60 9.1% 97.8%;
    --primary: 20.5 90.2% 48.2%;
    --primary-foreground: 60 9.1% 97.8%;
    --secondary: 0 0% 9%;
    --secondary-foreground: 60 9.1% 97.8%;
    --muted: 12 6.5% 15.1%;
    --muted-foreground: 24 5.4% 63.9%;
    --accent: 0 0% 16%;
    --accent-foreground: 60 9.1% 97.8%;
    --destructive: 0 72.2% 50.6%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 12 6.5% 15.1%;
    --input: 12 6.5% 15.1%;
    --ring: 20.5 90.2% 48.2%;
  }
}

@layer base {
  * {
    @apply border-border font-display tracking-[0.02rem];
  }
  body {
    @apply bg-neutral-100 dark:bg-neutral-900 text-foreground overflow-x-hidden;
  }

  .mapboxgl-control-container {
    @apply hidden;
  }

  ::-webkit-scrollbar {
    @apply h-full w-[5px];
  }

  ::-webkit-scrollbar-track {
    @apply h-[6px] w-[5px];
  }

  ::-webkit-scrollbar-thumb {
    @apply rounded bg-neutral-400 dark:bg-neutral-700;
  }
}

@layer components {
  .container {
    @apply max-w-[210ch] px-4 self-center;
  }

  .grid-dynamic {
    grid-template-columns: repeat(auto-fill, minmax(30ch, 1fr));
    @apply grid w-full gap-4;
  }

  .grid-dynamic-relaxed {
    grid-template-columns: repeat(auto-fill, minmax(36ch, 1fr));
    @apply grid w-full gap-4;
  }

  .grid-dynamic-tight {
    grid-template-columns: repeat(auto-fill, minmax(20ch, 1fr));
    @apply grid w-full gap-4;
  }

  .grid-dynamic-tighter {
    grid-template-columns: repeat(auto-fill, minmax(15ch, 1fr));
    @apply grid w-full gap-4;
  }

  .button-header {
    @apply px-4 py-2 text-center hover:bg-white/50 dark:hover:bg-white/10;
  }

  .button-header:hover,
  .button-header.active {
    @apply bg-white/40 dark:bg-white/5 shadow text-foreground;
  }
}

.mapboxgl-control-container {
  @apply hidden;
}

.rdp [aria-hidden="true"] {
  @apply hidden;
}

.rdp-vhidden {
  @apply hidden;
}

select::-webkit-scrollbar {
  display: none;
}
